.container-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 95%;
    background-color: $lightGreyColor;
    border-radius: 15px;
    padding: 15px;
    margin-top: 15px;
    height: 60px;

    &:hover {
        background-color: #d7e8f7;
    }

    .checkbox {
        margin-right: 15px;
    }

    .content {
        overflow: hidden;
        white-space: nowrap;
        flex: 1;

        p {
            margin: 0;
            font-weight: bold;
            font-size: 16px;
            color: primaryColor;
        }

    }

    &.completed {
        .content p {
            text-decoration: line-through;
            color: #999999;
        }
    }

    .completed-text {
        color: rgb(96, 140, 137);
        /* Defina a cor desejada para tarefas concluídas */
    }

    .expected-completion-text {
        color: $mediumGreyColor;
        /* Defina a cor desejada para tarefas não concluídas */
    }


    img {
        width: 20px;
        margin-right: 20px;
    }

    div {
        p {
            margin: 0px;
            color: $primaryColor;
            font-weight: bold;
            font-size: 16px;
        }

        span {
            font-size: 15px;
            color: $darkGreyColor;
            font-weight: normal;
        }
    }

    &.completed {

        // Estilização da tarefa concluída
        p {
            color: $primaryColor; // Alterado para cor escura
        }
    }

    @media screen and (min-width: 992px) {
        height: 75px;
        padding: 25px;

        img {
            width: 22px;
        }

        &.ativo {
            cursor: pointer;

            &:hover {
                border-color: $primaryColor;
                border-style: solid;
                border-width: 2px;
            }
        }
    }
}