@import './config.scss';

.container-filtros {
    top: 80px; // Defina uma distância maior do topo, ajuste conforme necessário
    background-color: $lightGreyColor; // Adicione uma cor de fundo
    border-radius: 12px; // Mantenha o mesmo raio de borda do container-filtros
    z-index: 998; // Defina um z-index menor do que o do cabeçalho
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: 95%;
    padding: 10px;
    position: relative; // Adicione position relative para controlar z-index

    .title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        span {
            font-size: 18px;
            letter-spacing: 0.01em;
            color: $primaryColor;
            font-weight: bold;
        }

        img {
            cursor: pointer;
        }

        .form {
            display: none;
        }
    }

    .filtrosMobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        div {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 8px;

            label {
                color: $primaryColor;
                font-size: 16px;
                min-width: 110px;
                margin-bottom: 0px;
            }
        }
    }

    @media screen and (min-width:992px) {
        padding: 20px;

        .filtrosMobile {
            display: none;
        }

        .title {
            img {
                display: none;
            }

            .form {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                label {
                    color: $primaryColor;
                    font-size: 16px;
                    margin-right: 10px;
                    margin-left: 10px;
                    margin-bottom: 0px;
                }

                input,
                select {
                    border-color: $primaryColor;
                }

                .line {
                    border-color: $secondaryColor;
                    border-width: 1px;
                    border-style: solid;
                    height: 40px;
                    width: 0px;
                    margin-left: 15px;
                }
            }
        }
    }
}
