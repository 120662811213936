@import './config.scss';

.container-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000; // Certifique-se de que o z-index seja maior que o dos outros elementos para garantir que o header fique por cima deles
    background-color: white; // Adicione uma cor de fundo se desejar  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid $lightGreyColor;
    padding: 10px 0 30px; // Ajustado o padding

    .main-content {
        padding-top: 100px; // Ajuste conforme necessário para corresponder à altura do seu header
      }

    .logo {
        width: 130px;
        margin-left: 20px;
    }

    .add-task-btn {
        display: none; // Escondido por padrão para telas menores
    }

    .mobile {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: $primaryColor;
            margin-right: 15px;
        }

        .sair {
            width: 35px;
            height: 35px;
            margin-right: 20px;
        }
    }

    .desktop {
        display: none; // Escondido por padrão para telas menores
    }

    // Media query para telas maiores
    @media screen and (min-width: 992px) {
        .mobile {
            display: none; // Escondido em telas maiores
        }

        .desktop {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 90px;

            span {
                font-size: 22px;
                color: $secondaryColor;
                margin-right: 25px;
            }

            img {
                cursor: pointer;
            }
        }

        // Mostrar o botão de adicionar tarefa em telas maiores
        .add-task-btn {
            display: flex;
            align-items: center;
            background-color: $secondaryColor;
            border-radius: 15px;
            width: 185px;
            height: 45px;
            padding: 5px;
            cursor: pointer;
            color: white;
            font-size: 18px;
            justify-content: center;

            .plus-icon {
                width: 20px; // Ajuste o tamanho do ícone conforme necessário
                height: 20px; // Ajuste o tamanho do ícone conforme necessário
                fill: white; // Preencha a cor do ícone conforme necessário
                margin-right: 8px; // Ajuste a margem conforme necessário
            }
        }
    }
}