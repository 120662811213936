@import './config.scss';

.container-listagem {
    overflow-y: auto; // Adiciona rolagem vertical quando necessário
    margin-top: 70px; // Ajuste conforme necessário
    padding: px; 
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    &.vazia {
        justify-content: center;
    }

    img {
        width: 280px;
    }

    p {
        margin-top: 15px;
        display: block;
        font-size: 15px;
        color: $primaryColor;
        font-weight: bold;
    }

    @media screen and (min-width: 992px) {
        img {
            width: 500px;
        }

        p {
            font-size: 28px;
        }
    }

    @media screen and (max-width: 991px) {
        overflow-y: auto; // Adiciona rolagem vertical para dispositivos móveis
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    button {
        padding: 8px 12px;
        margin: 0 5px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        background-color: $lightGreyColor;
        color: $mediumGreyColor;
        font-size: 14px;
        transition: background-color 0.3s, color 0.3s;

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }

        &:hover:not(:disabled) {
            background-color: $primaryColor;
            color: white;
        }
    }

    span {
        margin: 0 10px;
        color: $darkGreyColor;
        font-size: 14px;
    }

    .page-count {
        font-size: 14px;
        color: $darkGreyColor;
        margin: 0 10px;
    }
}
