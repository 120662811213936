@import './config.scss';

.container-modal{
    margin-top: 150px;

    button{
        width: 40%;
        height: 50px;
        background-color: $primaryColor;
        border-color: $primaryColor;
        cursor: pointer;
        color: white;
        border-radius: 12px;
        font-size: 15px;
    }

    p{
        text-align: center;
        color: $primaryColor;
        font-size: 17px;
        margin-top: 10px;
        font-weight: bold;
    }

    input{
        border-width: 0.5px;
        border-color: $mediumGreyColor;
        border-radius: 8px;
        padding: 10px;
        height: 50px;
        margin-top: 10px;

        &:focus{
            border-color: $primaryColor;
        }
    }

    .error{
        color: $errorColor;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        span{
            margin-left: 30px;
            color: $primaryColor;
            font-size: 15px;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .modal-content{
        border-radius: 15px;
    }

    .modal-footer{
        border-top: none;
    }

    @media screen and (min-width:992px) {

        p{
            font-size: 18px;
        }

        .modal-dialog{
            width: 20%;
        }

        .buttons{
            margin-bottom: 15px;
        }

        input{
            padding: 15px;
            border-radius: 14px;
            height: 55px;
        }
    }
}
