@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: 'Biennale Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fontes/biennale/Biennale\ Regular.otf') format('opentype');
}

html{
    height: 100%;
    font-family: 'Biennale Regular';
}

body{
    height: 100%;
    font-family: 'Biennale Regular';
}

#root{
    height: 100%;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 0px;
}

@import './config.scss';
@import './login.scss';
@import './header.scss';
@import './filtros.scss';
@import './listagem.scss';
@import './footer.scss';
@import './item.scss';
@import './modal.scss';

input, select{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: $primaryColor;
    min-width: 220px;
    background-color: transparent;
    font-family: 'Biennale Regular';

    border-top-width: 0em;
    border-left-width: 0em;
    border-right-width: 0em;
    border-bottom-width: 0.1em;
    border-color: $mediumGreyColor;

    outline: none;

    cursor: pointer;

    &:focus{
        border-color: $primaryColor;
    }

    &::placeholder{ // Chrome, Firefox, Opera Safari 10+
        color: $mediumGreyColor;
        opacity: 1;
    }

    &:-ms-input-placeholder{ //Internet Explore
        color: $mediumGreyColor;
    }

    &::-ms-input-placeholder{ // Microsoft Edge
        color: $mediumGreyColor;
    }
}

@media screen and (min-width: 992px) {
    input, select{
        border-top-width: 1px;
        border-left-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-radius: 8px;
        padding: 5px;
    }
}
