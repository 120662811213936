@import './config.scss';

.container-login {
    .logo {
        display: block;
        margin: auto;
        margin-bottom: 30px;
        width: 196.8px;
    }

    .error-message {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        padding: 10px 20px;
        border-radius: 0;
        width: 100%;
        text-align: center;
        z-index: 9999;
        transition: top 0.3s ease-in-out;
    }

    .interactive-message {
        cursor: pointer;
        color: $secondaryColor;
        font-family: "Space Grotesk-Medium", Helvetica;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        text-decoration: underline;
        margin-top: 10px; /* Ajuste a margem superior conforme necessário */
        margin-right: 5px;
      }
      
      

    p {
        text-align: center;
        color: $errorColor;
        font-weight: bold;
    }

    button {
        margin-top: 12px;
        width: 100%;
        height: 56px;
        background-color: $primaryColor;
        border-color: $primaryColor;
        color: white;
        border-radius: 12px;
        font-size: 17px;
    }

    .input {
        display: flex;
        flex-direction: row;
        padding: 10px;

        img {
            margin-top: -20px;
            margin-right: 10px;
        }

        input {
            margin-bottom: 12px;
            font-size: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: $primaryColor;
            width: 240px;
            border-radius: 0px;
            padding-bottom: 7px;

            border-top-width: 0px;
            border-left-width: 0px;
            border-right-width: 0px;
            border-color: $mediumGreyColor;
            border-bottom-width: 0.1em;

            outline: none;

            &:focus {
                border-color: $primaryColor;
            }

            &::placeholder {
                color: $mediumGreyColor;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $mediumGreyColor;
            }

            &::-ms-input-placeholder {
                color: $mediumGreyColor;
            }
        }
    }

    @media screen and (min-width:992px) {
        display: flex;
        flex-direction: row;

        .logo {
            width: 520px;
            margin: 0px;
        }

        form {
            border-style: solid;
            border-color: $primaryColor;
            border-width: 0.1em;
            border-radius: 32px;
            padding: 80px 40px;
            margin-left: 10%;

            .input {
                input {
                    width: 343px;
                }

                img {
                    width: 20px;
                }
            }
        }
    }
}